export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/admin/servers',
    name: 'admin-servers',
    component: () => import('@/views/gManager/pages/Servers.vue'),
  },
  {
    path: '/products/add',
    name: 'products-add',
    component: () => import('@/views/gManager/pages/AddProduct.vue'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/views/gManager/pages/Statistics.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/gManager/pages/Orders.vue'),
  },
  {
    path: '/order/:id',
    name: 'view-order',
    component: () => import('@/views/gManager/pages/ViewOrder.vue'),
  },
  {
    path: '/admin/users',
    name: 'admin-users-list',
    component: () => import('@/views/gManager/pages/Users.vue'),
  },
  {
    path: '/admin/add-user',
    name: 'admin-user-add',
    component: () => import('@/views/gManager/Administration/pages/AddUser.vue'),
  },
]
