import axios from 'axios';

const BaseApi = axios.create({
  baseURL: "https://api.gmanager.org/api/"
});

let Api = function() {
  let token = localStorage.getItem("accessToken");

  if (token) {
    BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return BaseApi;
};

export default Api;