import Api from "@/http/Api";

export default {
  namespaced: false,
  state: {
    productsList: []
  },

  getters: {
    productsList: state => {
      return state.productsList
    },
  },

  mutations: {
    GET_PRODUCTS(state, productsList) {
      state.productsList = productsList;
    },
  },

  actions: {
    GET_PRODUCTS({commit}) {
      Api().get('/products')
      .then(res => {
          {  
              commit('GET_PRODUCTS', res.data.products)
          }
      }).catch(err => {
          console.log(err)
      })
    },


  }
}