import Api from "@/http/Api";

export default {
  namespaced: false,
  state: {
    addServer: {
      name: '',
      ip: '',
      password: '',
      user: '',
    },
    addUser: {
      name: '',
      password: '',
      user: '',
    },
    userList: [],
    adminServers: [],
  },
  getters: {
    userList: state => {
      return state.userList
    },

    addUser: state => {
      return state.addUser
    },

    addServer: state => {
      return state.addServer
    },

    adminServers: state => {
      return state.adminServers
    },
  },
  mutations: {
    GET_USERS(state, userList) {
      state.userList = userList;
    },

    ADD_USER(state, user) {
      state.userList.push(user.user);
    },

    ADD_SERVER(state, server) {
      state.adminServers.push(server.server);
    },

    GET_SERVERS(state, adminServers) {
      state.adminServers = adminServers;
    }
  },
  actions: {
    GET_USERS({commit}) {
      Api().get('/admin/users')
      .then(res => {
          {  console.log(res.data)
              commit('GET_USERS', res.data.users)
          }
      }).catch(err => {
          console.log(err)
      })
    },

    ADD_USER({
      commit
    }, user) {
      commit('LOADING_SERVERS', true);
      Api().post('/admin/user', user).then(res => {
        if (res.data.response)
          console.log(res);
      }).catch(err => {
        console.log(err);
      })
    },

    GET_SERVERS({
      commit
    }) {
      Api().get('/admin/servers')
        .then(res => {
          {
            console.log(res.data)
            commit('GET_SERVERS', res.data.servers)
          }
        }).catch(err => {
          console.log(err)
        })
    },

    ADD_SERVER({
      commit
    }, server) {
      commit('LOADING_SERVERS', true);
      Api().post('/admin/server', server).then(res => {
        if (res.data.response)
          console.log(res);
      }).catch(err => {
        console.log(err);
      })
    },
  }
}