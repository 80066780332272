import Api from "@/http/Api";

export default {
    namespaced: false,
    state: {
        activeCountries: [],
        selectCountries: [],
    },
    getters: {
        activeCountries: state => {
            return state.activeCountries
        },

        selectCountries: state => {
            return state.selectCountries
        },
    },
    mutations: {
        GET_COUNTRIES(state, activeCountries) {
            state.activeCountries = activeCountries;
        },

        SELECT_COUNTRIES(state, selectCountries) {
            state.selectCountries = selectCountries;
        }
    },
    
    actions: {
        GET_COUNTRIES({
            commit
        }) {
            Api().get('/userCountries')
                .then(res => {
                    {
                        commit('GET_COUNTRIES', res.data.countries)
                    }
                }).catch(err => {
                    console.log(err)
                })
        },

        SELECT_COUNTRIES({
            commit
        }) {
            Api().get('/selectCountries')
                .then(res => {
                    {
                        commit('SELECT_COUNTRIES', res.data.countries)
                    }
                }).catch(err => {
                    console.log(err)
                })
        },
    }
}