import Api from "@/http/Api";

export default {
  namespaced: false,
  state: {
    addOrder: [],
    ordersList: [],
    orderView: null,
    selectedOrders: []
  },

  getters: {
    ordersList: state => {
      return state.ordersList
    },
    orderView: state => {
      return state.orderView
    },
    addOrder: state => {
        return state.addOrder
      },
  },

  mutations: {
    GET_ORDERS(state, ordersList) {
      state.ordersList = ordersList;
    },

    GET_ORDER(state, orderView) {
      state.orderView = orderView;
    },

    ADD_ORDER(state, order) {
        state.ordersList.unshift(order.order); // or push
    }
  },

  actions: {
    GET_ORDERS({commit}) {
      Api().get('/orders')
      .then(res => {
          {  
              commit('GET_ORDERS', res.data.orders)
          }
      }).catch(err => {
          console.log(err)
      })
    },

    GET_ORDER({commit}, id) {
      Api().get('/order/' + id)
      .then(res => {
          {  
            commit('GET_ORDER', res.data.order)
          }
      }).catch(err => {
          console.log(err)
      })
    },
  }
}